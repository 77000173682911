<template>
  <v-card class="card-custom">
    <v-card-text>
      <v-alert
        v-if="system.name === 'SBRHVI' && hasPreviousSbrhviResponsible"
        class="mt-3"
        type="info"
      >
        {{ $t("text.informativo_existe_responsavel_lider") }}
      </v-alert>
      <div class="pb-5 text-responsible">
        <h4>{{ $t("text.produtor_grupo_selecionado") }}</h4>
        <ul>
          <li>{{ grower.nome }}</li>
        </ul>
      </div>

      <div class="pb-5 text-responsible">
        <h4>
          {{ usersLabel }}
        </h4>
        <div v-if="users.length">
          <ul>
            <li :key="user.cpf" v-for="user in users">{{ user.nome }}</li>
          </ul>
        </div>
        <div v-else>Nenhum</div>
      </div>

      <div v-if="system.name === 'SBRHVI'" class="pb-5 text-responsible">
        <h4>
          {{ $t("text.titulo_demais_responsaveis_sbrhvi") }}
        </h4>

        <ul v-if="otherResponsibles.length">
          <li
            :key="`li-other-responsible-${otherResponsible.cpf}`"
            v-for="otherResponsible in otherResponsibles"
          >
            {{ otherResponsible.nome }}
          </li>
        </ul>
        <div v-else>
          {{ $t("text.titulo_nenhum_responsavel_cadastrado") }}
        </div>
      </div>

      <div class="pb-5 text-responsible" v-if="system.name !== 'SBRHVI'">
        <h4>
          {{ $t("text.unidades_produtivas_selecionadas") }}
        </h4>
        <div v-if="productiveUnits.length">
          <ul
            v-for="productiveUnit in productiveUnits"
            :key="productiveUnit.nome"
          >
            <li style="text-transform: capitalize;">
              {{ productiveUnit.nome_propriedade }}
            </li>
          </ul>
        </div>
        <div class="text-responsible" v-else>
          Nenhuma
        </div>
      </div>
      <div
        class="pb-5 text-responsible"
        v-if="grower.responsaveis_sbrhvi.length && system.name === 'SBRHVI'"
      >
        <h4>{{ $t("labels.harvest") }}</h4>
        <ul>
          <li>
            {{ grower.responsaveis_sbrhvi[0].safra }}
          </li>
        </ul>
      </div>
      <p class="text-responsible">
        {{ getMessageBySystemName(system.name) }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SumnmaryCard",

  props: ["system", "users", "otherResponsibles", "grower", "productiveUnits"],

  computed: {
    ...mapGetters({
      hasPreviousSbrhviResponsible: "invite/getHasSbrHviResponsible"
    }),
    usersLabel() {
      return this.system.name === "ABR/BCI"
        ? this.$t("text.titulo_responsaveis_selecionados_abr")
        : this.$t("text.titulo_responsaveis_selecionados_sbrhvi");
    }
  },

  methods: {
    getMessageBySystemName(name) {
      if (name === "ABR/BCI") {
        return "Se o convite for aceito até 30 de junho, o usuário terá acesso até o fim da safra vigente ao sistema ABR";
      }

      return "Se o convite for aceito, o usuário terá acesso permanente ao SBRHVI para o Produtor selecionado.";
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ul {
    list-style-type: none;
  }
}
</style>
